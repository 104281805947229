import styles from './QuantityTextBox.module.scss';
import { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { joinClasses } from 'utils/helpers';
import Tooltip from 'components/primitives/tooltip';
import { useOnChange } from 'utils/hooks';
import { normalizeBounds, strip } from './helpers';
import { ACCURACY, MOUSE_DOWN_DELAY, MOUSE_PRESS_DELAY } from './constants';
import { CrossSmallIcon } from 'components/primitives/icons';

const initialErrors = { minimum: false, maximum: false, step: false };
const quantityRegex = /^\d*(?:[.,]\d+)?$/;
const quantityInputRegex = /^\d*(?:[.,]\d*)?$/;
let btnDown = false;
let btnTimeout;
let scrollTimeout;
let btnInterval;

const QuantityTextBox = ({
  className,
  id = 'quantity_Input',
  disabled = false,
  quantity,
  product,
  line,
  value,
  hideControlsOnBlur,
  showControlsUnderInputMd,
  tooltipOptions,
  validateBeforeOnChange,
  onChange,
  onKeyDown,
  separator,
  allowResetOnStepDecrease,
  noLabel,
  onBlur,
  ...props
}) => {
  const [errors, setErrors] = useState(initialErrors);
  const inputRef = useRef();
  const tooltipIconRef = useRef();
  const controlsHiddenRef = useRef(hideControlsOnBlur);
  const cntRef = useRef();
  const displayRefs = useDisplayRef(separator);

  const { step, minimum, maximum, allowEmpty } = quantity;
  useOnChange(() => {
    if (value === displayRefs.number)
      return;

    displayRefs.number = value;
    displayRefs.stringValue = value ? stringify(value, displayRefs.separator) : '';
  }, [value]);

  useOnChange(() => {
    if (separator === displayRefs.separator || !displayRefs.stringValue)
      return;

    displayRefs.separator = separator;
    displayRefs.stringValue = stringify(displayRefs.number, separator);
  }, [separator]);

  const validate = value => {
    const errors = getErrors(value, allowEmpty, minimum, maximum, step);
    setErrors(errors);
    return !errors.step && !errors.minimum && !errors.maximum;
  };

  useEffect(() => {
    if (disabled)
      setErrors(initialErrors);
    else if (!validate(displayRefs.stringValue))
      onChange({ value: displayRefs.number, isValid: false });
  }, [displayRefs.stringValue, disabled]);

  useEffect(() => () => {
    clearTimeout(btnTimeout);
    clearTimeout(scrollTimeout);
    clearInterval(btnInterval);
  }, []);

  const handleChange = e => {
    const newQuantity = e.target.value;
    if (!quantityInputRegex.test(newQuantity))
      return;

    if (validateBeforeOnChange && validateBeforeOnChange(newQuantity))
      return;

    const [number, separator] = getNumberAndSeparator(newQuantity);
    displayRefs.number = number;
    displayRefs.stringValue = newQuantity;

    if (separator)
      displayRefs.separator = separator;

    const isValid = validate(newQuantity);
    onChange({ value: isValid ? number : newQuantity, isValid });
  };

  const stepValue = (value, delta) => {
    const [parsed, separator] = getNumberAndSeparator(value);
    let newQuantity = strip(Math.round(parsed / step) * step + delta);

    if (allowResetOnStepDecrease && delta < 0 && newQuantity < minimum) {
      if (displayRefs.number === null)
        return;

      displayRefs.number = null;
      displayRefs.stringValue = '';
      onChange({ value: null, isValid: true });
      return;
    }

    newQuantity = normalizeBounds(newQuantity, minimum, maximum);

    if (separator)
      displayRefs.separator = separator;

    displayRefs.number = newQuantity;
    displayRefs.stringValue = stringify(newQuantity, displayRefs.separator);

    const isValid = validate(newQuantity);
    onChange({ value: newQuantity, isValid });
  };

  const handleKeyDown = e => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      stepValue(inputRef.current.value, e.key === 'ArrowUp' ? step : -step);
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => handleInputContentScrolling(inputRef.current));
    }

    onKeyDown && onKeyDown(e);
  };

  const spinValue = e => {
    e.preventDefault();

    if (e.button !== 0)
      return;

    const delta = Number(e.currentTarget.value);
    stepValue(inputRef.current.value, delta);
    scrollTimeout = setTimeout(() => handleInputContentScrolling(inputRef.current));
    btnTimeout = setTimeout(() => {
      btnInterval = setInterval(() => {
        stepValue(inputRef.current.value, delta);
        handleInputContentScrolling(inputRef.current);
      }, MOUSE_PRESS_DELAY);
    }, MOUSE_DOWN_DELAY);
    btnDown = true;
  };

  const releaseButton = () => {
    if (!btnDown)
      return;

    clearTimeout(btnTimeout);
    clearInterval(btnInterval);
    inputRef.current.focus();
    btnDown = false;
  };

  const handleFocus = hideControlsOnBlur
    ? e => {
      const isFocusEvent = e.type === 'focus';
      controlsHiddenRef.current = !isFocusEvent;
      if (isFocusEvent) {
        cntRef.current.classList.remove(styles.controlsHidden);
      } else {
        cntRef.current.classList.add(styles.controlsHidden);
        inputRef.current.scrollLeft = 0;
        if (onBlur)
          handleBlur(e.target.value);
      }
    }
    : e => {
      if (e.type !== 'blur')
        return;

      inputRef.current.scrollLeft = 0;
      if (onBlur)
        handleBlur(e.target.value);
    };

  const handleBlur = quantity => {
    const [number] = getNumberAndSeparator(quantity);

    const isValid = validate(quantity);
    onBlur({ value: isValid ? number : quantity, isValid });
  };

  return (
    <span
      className={
        joinClasses(
          className,
          styles.quantity,
          (errors.minimum || errors.maximum || errors.step) && 'invalid',
          controlsHiddenRef.current && styles.controlsHidden,
          showControlsUnderInputMd && styles.controlsOnBottomMd,
        )
      }
      ref={cntRef}
    >
      <span className={styles.spinnerBox}>
        <button
          type="button"
          disabled={disabled}
          tabIndex="-1"
          value={-step}
          onMouseDown={spinValue}
          onMouseUp={releaseButton}
          onMouseLeave={releaseButton}
          aria-hidden
        >
          <span>−</span>
        </button>
        <span className={styles.field}>
          {!noLabel &&
            <label htmlFor={id} className="visually-hidden">
              <SimpleText textKey="Quantity" />
            </label>
          }
          <input
            id={id}
            ref={inputRef}
            type="text"
            role="spinbutton"
            disabled={disabled}
            maxLength="8"
            value={displayRefs.stringValue || ''}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleFocus}
            aria-valuemax={maximum}
            aria-valuemin={minimum}
            aria-valuenow={displayRefs.number || Number(displayRefs.stringValue) || 0}
            aria-keyshortcuts="ArrowUp ArrowDown"
            autoComplete="off"
            {...props}
          />
          {(errors.minimum || errors.maximum || errors.step) && (
            <span className={styles.message} tabIndex="0">
              <Tooltip
                id={`${id}_error`}
                bodyClassName={styles.tip}
                body={(
                  <span>
                    {errors.minimum && <span><SimpleText textKey="ProductDetails_MinimumQuantityValidation" formatWith={[minimum]} /></span>}
                    {errors.maximum && <span><SimpleText textKey="ProductDetails_MaximumQuantityValidation" formatWith={[maximum]} /></span>}
                    {errors.step && <span><SimpleText textKey="Validation_QuantityValue" formatWith={[step]} /></span>}
                  </span>
                )}
                sign={false}
                showOnRight
                options={tooltipOptions}
                anchorRef={tooltipIconRef}
              >
                <span className={styles.iconWrapper} ref={tooltipIconRef}>
                  <CrossSmallIcon className={styles.icon} />
                </span>
              </Tooltip>
            </span>
          )}
        </span>
        <button
          type="button"
          disabled={disabled}
          tabIndex="-1"
          value={step}
          onMouseDown={spinValue}
          onMouseUp={releaseButton}
          onMouseLeave={releaseButton}
          aria-hidden
        >
          <span>+</span>
        </button>
      </span>
    </span>
  );
};

QuantityTextBox.propTypes = {
  className: PropTypes.string,
  quantity: PropTypes.shape({
    step: PropTypes.number.isRequired,
    minimum: PropTypes.number.isRequired,
    maximum: PropTypes.number.isRequired,
    allowEmpty: PropTypes.bool.isRequired,
  }),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  validateBeforeOnChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  hideControlsOnBlur: PropTypes.bool,
  showControlsUnderInputMd: PropTypes.bool,
  tooltipOptions: PropTypes.object,
  onKeyDown: PropTypes.func,
  separator: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  allowResetOnStepDecrease: PropTypes.bool,
  noLabel: PropTypes.bool,
  line: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  onBlur: PropTypes.func,
};

export default memo(QuantityTextBox);

function useDisplayRef(separator) {
  const displayRef = useRef();
  if (!displayRef.current)
    displayRef.current = { separator };

  return displayRef.current;
}

export function parseNumber(number) {
  return (typeof number === 'string' && number !== '')
    ? parseFloat(number.replace(',', '.'))
    : number;
}

function getNumberAndSeparator(numberStr) {
  if (typeof numberStr !== 'string' || !numberStr)
    return [numberStr, null];

  let separator;
  const stringWithDot = numberStr.replace(',', match => {
    separator = match;
    return '.';
  });
  return [parseFloat(stringWithDot), separator];
}

function getErrors(value, allowEmpty, minimum, maximum, step) {
  if (!value && allowEmpty)
    return initialErrors;

  const normalizedValue = parseNumber(value);
  return {
    minimum: normalizedValue !== '' && minimum > normalizedValue,
    maximum: normalizedValue !== '' && maximum < normalizedValue,
    step: (!value || !quantityRegex.test(value)) || (Number(normalizedValue + 'e' + ACCURACY) % Number(step + 'e' + ACCURACY) !== 0),
  };
}

function stringify(number, separator) {
  if (isNaN(number))
    return number;

  let stringified = number.toString();
  if (separator && separator !== '.')
    stringified = stringified.replace('.', separator);

  return stringified;
}

function handleInputContentScrolling(inputElement) {
  // Input allows to show 5 characters without overflowing, so no scrolling needed
  if (inputElement.value.length <= 5)
    return;

  const { offsetWidth, scrollWidth } = inputElement;
  if (scrollWidth > offsetWidth) {
    inputElement.scrollLeft = scrollWidth - offsetWidth;
  } else {
    // Legacy MS Edge and IE11 do not set actual scrollWidth for overflowed content,
    // max input value length is 8 characters, so double offsetWidth can be set as a scrollLeft value
    inputElement.scrollLeft = offsetWidth * 2;
  }
}
